import moment from 'moment-timezone';

jQuery( document ).ready( function ( $ ) {
	// Countdown
	var release_date = moment.tz( '2022-06-23 23:00:00', 'America/Chicago' );
	$( '#countdown' ).countdown( release_date.toDate(), function ( event ) {
		$( this ).html( event.strftime( '<div><div><span>%D</span>:</div></div><div><div><span>%H</span>:</div></div><div><div><span>%M</span>:</div></div><div><div><span>%S</span></div></div>' ) );
	} );

	// Tour
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/id_15503053/events?app_id=45PRESS_PILLBOX_PATTI',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					html += '<div>';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-location">' + event.venue.location + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						if ( offer.type === 'Tickets' ) {
							html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
						}
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( '<div class="no-events">No upcoming events.</div>' );
			}
		}
	} );
} );
